import React, { Component } from "react";
import ReportRegisterFirstPage from "./ReportRegisterFirstPage";
import ReportRegisterSecondPage from "./ReportRegisterSecondPage";
import ReportRegisterThirdPage from "./ReportRegisterThirdPage";
import { Helmet } from "react-helmet";
import {fetchReportToken} from "../../../actions";

class ReportRegister extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 1,
      isValid: false
    };
  }

  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  render() {
    const { page } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Accountability | Comprehensive 3-in-1 Commercial Credit Report |
            Register
          </title>
          <meta
            name="description"
            content="The Comprehensive 3-in-1 Commercial Credit Report offers a thorough assessment of a business's creditworthiness by combining data from Inoxico, TransUnion, and XDS. This enables companies to gain an in-depth understanding of the financial and operational aspects of a potential partner or client, assisting with enabling informed decision-making and risk assessment."
          />
          <link rel="canonical" href={window.location} />
        </Helmet>

        {page === 1 && (
          <ReportRegisterFirstPage
            nextPage={this.nextPage}
            onSubmit={this.fetchReportToken}
          />
        )}
        {page === 2 && (
          <ReportRegisterSecondPage
            previousPage={this.previousPage}
            nextPage={this.nextPage}
          />
        )}
        {page === 3 && <ReportRegisterThirdPage nextPage={this.nextPage} />}
      </React.Fragment>
    );
  }
}

// const mapStateToprops = (state) => {
//   return {
//     token: this.state.threeInOneReport.public_3in1_token,
//   }
// }

export default ReportRegister;
