import React from "react";
import FormGroup from "./FormGroup";
import ReactHtmlParser from "react-html-parser";

export const renderField = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <div>
      <input {...input} placeholder={label} type={type} />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

export const renderError = ({ error, asyncValidate, touched }) => {
  if (error && touched) {
    return (
      <div className="error-message">
        <div className="">{ReactHtmlParser(error)}</div>
      </div>
    );
  }

  if (asyncValidate) {
    return (
      <div className="error-message">
        <div className="">{ReactHtmlParser(asyncValidate)}</div>
      </div>
    );
  }

  return null;
};

export const renderGenError = ({ error, touched }) => {
  if (error) {
    return (
      <div className="error-message">
        <div className="">{ReactHtmlParser(error)}</div>
      </div>
    );
  }

  return null;
};

export const renderInput = ({ input, label, meta, type, val, nomargin }) => {
  const errorClass = `field ${meta.error && meta.touched ? "error" : ""}`;

  if (val) {
    return (
      <div className={`${errorClass}`}>
        <FormGroup noMargin={nomargin}>
          <input type={type} {...input} placeholder={label} value={val} />
          {renderError(meta)}
        </FormGroup>
      </div>
    );
  }

  return (
    <div className={`${errorClass}`}>
      <FormGroup>
        <input type={type} {...input} placeholder={label} />
        {renderError(meta)}
      </FormGroup>
    </div>
  );
};

export const renderHiddenInput = ({ input, label, meta, type, val }) => {
  const errorClass = `field ${meta.error && meta.touched ? "error" : ""}`;

  return (
    <div className={`${errorClass}`}>
      <input type={type} {...input} placeholder={label} value={val} />
      {/* {renderError(meta)} */}
    </div>
  );
};

export const renderInputWithLabel = ({ input, label, meta, type, note }) => {
  const errorClass = `field ${meta.error && meta.touched ? "error" : ""}`;

  return (
    <div className={`${errorClass}`}>
      <FormGroup>
        <label htmlFor="">{label}</label>
        <p htmlFor="" class="x-small no-padding">{note}</p>
        <input {...input} type={type} />
        {renderError(meta)}
      </FormGroup>
    </div>
  );
};

export const renderCheckbox = ({ input, label, meta, type, noMargin }) => {
  const errorClass = `field ${meta.error && meta.touched ? "error" : ""}`;
  if (noMargin) {
    return (
      <div className={`${errorClass}`}>
        <FormGroup checkbox noMargin>
          <label style={{ fontSize: "15px" }} htmlFor="">
            {ReactHtmlParser(label)}
          </label>
          <input type={type} {...input} /> <br />
        </FormGroup>
        {renderError(meta)}
      </div>
    );
  }

  return (
    <div className={`${errorClass}`}>
      <FormGroup checkbox>
        <label htmlFor="">{label}</label>
        <input type={type} {...input} />
        {renderError(meta)}
      </FormGroup>
    </div>
  );
};

export const renderMultiSelect = ({ input, label, meta, options }) => {
  const errorClass = `field ${meta.error && meta.touched ? "error" : ""}`;
  const renderOptions = () => {
    return options.map((option, i) => {
      return (
        <option key={i} value={option.val}>
          {option.val}
        </option>
      );
    });
  };

  return (
    <div className={`${errorClass}`}>
      <FormGroup>
        <label htmlFor="">{label}</label>
        <select {...input}>{renderOptions()}</select>

        {renderError(meta)}
      </FormGroup>
    </div>
  );
};

export const renderIndexedSelect = ({ input, label, meta, options }) => {
  const errorClass = `field ${meta.error && meta.touched ? "error" : ""}`;
  const renderOptions = () => {
    return options.map((option, i) => {
      return (
        <option key={i} value={option.key}>
          {option.val}
        </option>
      );
    });
  };

  return (
    <div className={`${errorClass}`}>
      <FormGroup>
        <label htmlFor="">{label}</label>
        <select {...input}>{renderOptions()}</select>

        {renderError(meta)}
      </FormGroup>
    </div>
  );
};

export const renderSelect = ({ input, label, meta, options }) => {
  const errorClass = `field ${meta.error && meta.touched ? "error" : ""}`;
  const renderOptions = () => {
    return options.map((option, i) => {
      return (
        <option key={i} value={option.toLowerCase()}>
          {option}
        </option>
      );
    });
  };

  return (
    <div className={`${errorClass}`}>
      <FormGroup>
        {label && <label htmlFor="">{label}</label>}
        <select {...input}>{renderOptions()}</select>

        {renderError(meta)}
      </FormGroup>
    </div>
  );
};


export const renderValueSelect = ({ input, label, meta, options }) => {
  const errorClass = `field ${meta.error && meta.touched ? "error" : ""}`;

  const renderOptions = () => {
    return options.map((option, i) => {
      let optionValue, optionLabel;

      // Check if the option is an object with 'value' and 'name'
      if (typeof option === 'object' && option !== null) {
        optionValue = option.value || ''; // Default to an empty string if value is not present
        optionLabel = option.name || ''; // Default to an empty string if name is not present
      } else {
        optionValue = option; // option is a string
        optionLabel = option; // option is a string
      }

      // Only call toLowerCase if optionValue is a string
      const valueToUse = optionValue;

      return (
          <option key={i} value={valueToUse}>
            {optionLabel}
          </option>
      );
    });
  };

  return (
      <div className={`${errorClass}`}>
        <FormGroup>
          {label && <label htmlFor="">{label}</label>} {/* For accessibility */}
          <select {...input}>{renderOptions()}</select>

          {renderError(meta)}
        </FormGroup>
      </div>
  );
};

export const renderSelect2 = ({ input, label, meta, options, children }) => {
  const errorClass = `field ${meta.error && meta.touched ? "error" : ""}`;
  return (
    <div className={`${errorClass}`}>
      <FormGroup>
        {label && <label htmlFor="">{label}</label>}
        <select {...input}>{children}</select>

        {renderError(meta)}
      </FormGroup>
    </div>
  );
};

export const renderAssocSelect = ({ input, label, meta, options }) => {
  const errorClass = `field ${meta.error && meta.touched ? "error" : ""}`;
  const renderOptions = () => {
    return options.map((option, i) => {
      return (
        <option key={i} value={option.optionNumber}>
          {option.optionDescription}
        </option>
      );
    });
  };

  return (
    <div className={`${errorClass}`}>
      <FormGroup>
        {label && <label htmlFor="">{label}</label>}
        <select {...input}>{renderOptions()}</select>

        {renderError(meta)}
      </FormGroup>
    </div>
  );
};

export class FieldFileInput extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const {
      input: { onChange }
    } = this.props;
    onChange(e.target.files[0]);
  }

  render() {
    const { input, label, meta, inputName, btnClass } = this.props; 
    const uploadBtnClass = `upload-btn ${btnClass ? btnClass : ""}`;
    return (
      <div>
        <div className="upload-btn-wrapper">
          <button className={uploadBtnClass}>{label}</button>
          <input
            name={inputName}
            type="file"
            accept=".jpg, .png, .jpeg, .pdf, .docx"
            onChange={this.onChange}
          />
        </div>
        {renderError(meta)}
      </div>
    );
  }
}

export const renderTextArea = ({ input, label, meta }) => {
  const errorClass = `field ${meta.error && meta.touched ? "error" : ""}`;

  return (
    <div className={`${errorClass}`}>
      <FormGroup>
        <textarea
          {...input}
          placeholder={label}
          name={input.name}
          cols="30"
          rows="10"
        ></textarea>
        {renderError(meta)}
      </FormGroup>
    </div>
  );
};

// export const renderRadioButton = ({input, label, meta, value, name}) => {
  
//   return (
//      <label>
//         <input name={name}
//                type="radio"
//                value={value}
//                />
//         <span>{label}</span>
//      </label>
//   );
// };

export const renderRadioButton = ({
  input,
  label,
  meta: { touched, error }
}) => (
  <div>
    
    <div>
      <input {...input} type="radio" /><label>{label}</label>
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);