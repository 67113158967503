import React, { Component } from "react";
import { connect } from "react-redux";
import { cancelPayment } from "../../actions/index";
import Container from "../ui/container/Container";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import HideOnMobile from "../ui/structure/hide-on-mobile/HideOnMobile";
import {downloadPDF, getQueryString} from "../../utils/functions/index";
import _ from "lodash";

class PaymentCancel extends Component {
  componentDidMount() {
      const tokenTypes = ["4in1", "3in1"];
      let reportType = null;
      let tokenType = null;

      for (const type of tokenTypes) {
          const queryParam = getQueryString("tokenType");
          if (queryParam === type) {
              tokenType = type;
              reportType = type === "4in1" ? "4in1_report" : "3in1_report";
              break;
          }
      }

      if (reportType) {
          this.props.cancelPayment(reportType, getQueryString("tokens"));
      }
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <div
            className="form-success"
            style={{
              height: "600px",
              position: "relative",
              display: "flex",
              justifyContent: "left",
              alignItems: "center"
            }}
          >
            <div className="thank-you-content">
              <Text type="h2" text="Your order has been canceled" />

              <Button
                type="redirect"
                theme="green"
                text="BACK TO HOME"
                url="/"
              />
            </div>

            <HideOnMobile>
              <div
                className="thank-you-image"
                style={{ position: "absolute", bottom: 0, right: 0 }}
              >
                <img
                  src="/assets/image/people/Lady.png"
                  alt=""
                  style={{ maxWidth: "340px" }}
                />
              </div>
            </HideOnMobile>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  { cancelPayment }
)(PaymentCancel);
