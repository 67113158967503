import "./CustomSlider.scss";
import React, { Fragment } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

export default class Gallery extends React.Component {
  render() {
    const controlClassnames = [this.props.whiteIcons && "white-icon"];

    const renderControls = () => {
      if (!this.props.disableAll)
        return (
          <Fragment>
            <SliderControlNext />
            <SliderControlPrev />
          </Fragment>
        );

      return null;
    };

    const SliderControlNext = () => {
      const handleNext = () => {
        
        // Check if Carousel and its state are defined
        if (this.Carousel && this.Carousel.state) {
          const currentIndex = this.Carousel.state.activeIndex;
    
          if (currentIndex === this.props.children.length - 1) {
            this.Carousel.slideTo(0)
          } else {
            this.Carousel.slideNext(); // Go to next item
          }
        }
      };
      return (
        <button
          className={`slider-arrow slider-next ${controlClassnames.join(" ")}`}
          onClick={handleNext}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      );
    };

    const SliderControlPrev = (props) => {
      const handlePrev = () => {
        
        // Check if Carousel and its state are defined
        if (this.Carousel && this.Carousel.state) {
          const currentIndex = this.Carousel.state.activeIndex;
    
          if (currentIndex === 0) {
            this.Carousel.slideTo(this.props.children.length - 1)
          } else {
            this.Carousel.slidePrev(); // Go to previous item
          }
        }
      };
    
      return (
        <button
          className={`slider-arrow slider-prev ${controlClassnames.join(" ")}`}
          onClick={handlePrev}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
      );
    };

    return (
      <div className="slider-container">
        <AliceCarousel
          mouseDragEnabled={this.props.disableAll ? false : true}
          dotsDisabled={
            this.props.disableAll || this.props.dotsDisabled ? true : false || this.props.dotsMobile && window.innerWidth > 440 && true 
          }
          disableButtonsControls={true}
          ref={el => (this.Carousel = el)}
          autoPlay={true}
          autoPlayInterval={13000}
          stopAutoPlayOnHover={false}
          fadeOutAnimation={true}
        >
          {this.props.children}
        </AliceCarousel>

        {window.innerWidth > 440 && renderControls()}
      </div>
    );
  }
}
