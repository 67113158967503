import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { update3in1Payment } from "../../../actions/index";
import { buildPayfastQuery } from "../../../utils/functions/index";
import Button from "../../ui/button/Button";
import CenteredBox from "../../ui/structure/CenteredBox";
import validate from "./validate";
import Text from "../../ui/text/Text";
import { v4 as uuid } from 'uuid';
import {DATA_3_IN_1_REPORT, FETCH_3_IN_1_TOKEN} from "../../../actions/types";
import accountability from "../../../api/accountability";

class ReportRegisterThirdPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.threeInOneReport.public_3in1_token) {
        const selectedReports = this.props.threeInOneReport.payment.selectedReports;
        // Store selectedReports in state for future use
        this.setState({ selectedReports });

      this.props.update3in1Payment(
        this.props.threeInOneReport.public_3in1_token,
        selectedReports
      );
    }
  }

  onSubmit = (formValues) => {
    const public_3in1_token = this.props.threeInOneReport.public_3in1_token;
    const public_3in1_payment_token = this.props.threeInOneReport.payment.public_3in1_payment_token;

      // Capture selectedReports from either state or props
      const selectedReports = this.state?.selectedReports || this.props.threeInOneReport.payment.selectedReports;

      if (!selectedReports) {
          console.error("No selected reports found!");
          return;
      }

      console.log('Selected Reports:', selectedReports);

      localStorage.setItem("user3iN1ReportResult", JSON.stringify(selectedReports));

      this.props.dispatch({
          type: DATA_3_IN_1_REPORT,
          payload: selectedReports
      });

    const data = {
      public_3in1_token: public_3in1_token,
      public_3in1_payment_token: public_3in1_payment_token,
      payment_id: uuid(),
      type: "3in1_report"
    };

    console.log('data');
    console.log(data);
    let lsuser = JSON.parse(localStorage.getItem("user3iN1Tokens"));
    const queryURL = buildPayfastQuery({
      userDetails: {
        name: lsuser.public_3in1_name,
        email_address: lsuser.public_3in1_email
      },
        data,
      amount: 926.76,
      itemName: "Comprehensive 3-in-1 Report",
      returnURL: `/payment-complete?public_3in1_tokens=${JSON.stringify(
          data
      )}`,
      cancelURL: "/payment-cancel?tokenType=3in1"
    });
    // window.location.href = `/payment-complete?public_3in1_tokens=${JSON.stringify(data)}`;
    window.location.href = queryURL;
  };

  renderPayfastForm = () => {
    const { handleSubmit } = this.props;

    return (
      <form
        onSubmit={handleSubmit(this.onSubmit)}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <img src="../../../assets/image/payfast-logo.svg" alt="payfast-logo" />
        <div className="spacer"></div>
        <Text
          type="p"
          className="centered medium p-t-60"
          text="This is the PayFast process. When this form is completed and payment has been made you will be redirected to a thank you page."
        />
        <CenteredBox>
          <Button type="submit" text="NEXT" theme="green" />
        </CenteredBox>
      </form>
    );
  };

  render() {
    return (
      <div
        style={{
          position: "fixed",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          zIndex: 999,
          background: "#fff"
        }}
      >
        {this.renderPayfastForm()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    threeInOneReport: state.threeInOneReport
  };
};

const formWrapped = reduxForm({
  form: "wizard", //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(ReportRegisterThirdPage);

export default connect(mapStateToProps, { update3in1Payment })(formWrapped);
