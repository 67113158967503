import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { generateReport } from "../../actions/index";
import Container from "../ui/container/Container";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import { getQueryString } from "../../utils/functions/index";
import HideOnMobile from "../ui/structure/hide-on-mobile/HideOnMobile";
import { downloadPDF } from "../../utils/functions/index";
import Loader from "../ui/loader/Loader";

class PaymentSuccess extends Component {

  generateReport = async () => {
    const tokenTypes = ["public_4in1_tokens", "public_3in1_tokens"];
    let reportType = null;
    let tokens = null;
    let report = null;
    let downloadReport = null;
    let reportName = "";

    for (const type of tokenTypes) {
      tokens = JSON.parse(decodeURIComponent(getQueryString(type)));
      if (tokens) {
        reportType = type.includes("4in1") ? "fourInOneReport" : "threeInOneReport";
        break;
      }
    }

    if (tokens && reportType) {
      if(reportType === "threeInOneReport"){

        await this.props.generateReport(reportType, tokens.public_3in1_token);
        report = this.props.threeInOneReport;
        downloadReport = report.report.public_3in1_report_data;
        reportName = "Comprehensive 3-in-1 Report"; // Set report name for 3-in-1
      }else{
        await this.props.generateReport(reportType, tokens.public_4in1_token);
        report = this.props.fourInOneReport;
        downloadReport = report.report.public_4in1_report_data;
        reportName = "Comprehensive 4-in-1 Report"; // Set report name for 4-in-1
      }

      if (!_.isEmpty(report)) {
        downloadPDF(downloadReport, reportName);
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <div
            className="form-success"
            style={{
              height: "600px",
              position: "relative",
              display: "flex",
              justifyContent: "left",
              alignItems: "center"
            }}
          >
            <div className="thank-you-content">
              <Text type="h2" text="Payment successful!" />
              <Text type="p" text="We wish to thank you for your payment." />
              <Text
                type="p"
                text="Please click on the button below to generate your report."
              />
              <hr />
              <br />

              {this.props.isLoading && <Loader />}

              {!this.props.isLoading && (
                <Button
                  onClick={this.generateReport}
                  theme="green"
                  text="GENERATE REPORT"
                />
              )}
            </div>

            <HideOnMobile>
              <div
                className="thank-you-image"
                style={{ position: "absolute", bottom: 0, right: 0 }}
              >
                <img
                  src="/assets/image/people/Lady.png"
                  alt=""
                  style={{ maxWidth: "340px" }}
                />
              </div>
            </HideOnMobile>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  const tokenTypes = ["public_4in1_tokens", "public_3in1_tokens"];

  for (const type of tokenTypes) {
    const tokens = JSON.parse(decodeURIComponent(getQueryString(type)));
    if (tokens) {
      if(type.includes("4in1")){
        return {
          fourInOneReport: state.fourInOneReport,
          isLoading: state.isLoading
        };
      }else{
        return {
          threeInOneReport: state.threeInOneReport,
          isLoading: state.isLoading
        };
      }
      break;
    }
  }


};

export default connect(mapStateToProps, { generateReport })(PaymentSuccess);
