import {
  FETCH_3_IN_1_TOKEN,
  FETCH_3_IN_1_RESULTS,
  CREATE_3_IN_1_PAYMENT,
  UPDATE_3_IN_1_PAYMENT,
  DATA_3_IN_1_REPORT,
  GENERATE_REPORT
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_3_IN_1_TOKEN:
      return { ...state, public_3in1_token: action.payload.public_3in1_token };
    case CREATE_3_IN_1_PAYMENT:
      return { ...state, payment: action.payload };
    case UPDATE_3_IN_1_PAYMENT:
      return { ...state, payment: action.payload };
    case FETCH_3_IN_1_RESULTS:
      return { ...state, search_result: action.payload };
    case DATA_3_IN_1_REPORT:
      return { ...state, selected_reports: action.payload };
    case GENERATE_REPORT:
      return { ...state, report: action.payload };
    default:
      return state;
  }
}
