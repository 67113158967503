import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchResults,
  submit3in1SearchResult,
  throwError,
} from "../../../actions/index";
import { reduxForm } from "redux-form";
import validate from "./validate";
import Button from "../../ui/button/Button";
import CenteredBox from "../../ui/structure/CenteredBox";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import Container from "../../ui/container/Container";
import Text from "../../ui/text/Text";
import Spacer from "../../ui/structure/spacer/Spacer";
import Loader from "../../ui/loader/Loader";
import scrollToFirstError from '../SmoothScrollToErrorFields.js';

class ReportRegisterSecondPage extends Component {

  constructor(props) {
    super(props);
    // Initialize state to track the selected report
    this.state = {
        selectedResults: {
          inoxico: null,
          tu: null,
          xds: null,
        },
    };
  }

  // Function to handle radio button selection for each report type
  handleSelection = (reportKey, selectedIndex) => {
    this.setState((prevState) => ({
      selectedResults: {
        ...prevState.selectedResults,
        [reportKey]: selectedIndex, // Update the selected result for the given report
      },
    }));
  };

  componentDidMount() {
    console.log('componentDidMount');
    console.log(this.props.token);
    if (this.props.token) {
      this.props.fetchResults(this.props.token);
    }
  }

  renderSearchData = () => {

    if (!this.props.search_result) {
      return <Loader />;
    }
    if (this.props.search_result) {

      const reportData = [
        { key: 'inoxico', data: this.props.search_result.public_3in1_report_data.inoxico },
        { key: 'tu', data: this.props.search_result.public_3in1_report_data.tu },
        { key: 'xds', data: this.props.search_result.public_3in1_report_data.xds }
      ];

      return (
          <React.Fragment>
            <CenteredBox noPadding stacked alignItems={"flex-start"}>
              {reportData.map((report, reportIndex) => {
                if (report.data.message === 'Success') {
                  let resultMessage = report.data.results;
                  let name = report.key.toUpperCase();
                  if(report.key === 'tu'){
                    name = 'TRANSUNION';
                  }
                  return (
                      <div key={reportIndex} style={{ paddingBottom: '20px', textAlign: 'left' }}>
                        <h3>{name} RESULT</h3>
                        <div style={{ marginBottom: '10px', paddingBottom: '10px', borderBottom: '1px solid #ccc' }}>
                          <label>
                            <div style={{display: 'inline-block', marginLeft: '10px'}}>
                              <table style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                                tableLayout: 'fixed',
                                textAlign: 'left'
                              }}>
                                <thead>
                                <tr>
                                  <th style={{width: '10%', paddingBottom: '10px' }}></th> {/* Column for the radio button */}
                                  <th style={{width: '30%', paddingBottom: '10px'}}>Name</th>
                                  <th style={{width: '30%', paddingBottom: '10px'}}>Reg No</th>
                                  <th style={{width: '30%', paddingBottom: '10px'}}>Enquiry ID</th>
                                </tr>
                                </thead>
                              </table>
                            </div>
                          </label>
                        </div>
                        {resultMessage.map((item, index) => (
                            <div key={index} style={{ marginBottom: '10px', paddingBottom: '10px', borderBottom: '1px solid #ccc' }}>
                              <label>
                                <div style={{display: 'inline-block', marginLeft: '10px'}}>
                                  <table style={{
                                    width: '100%',
                                    borderCollapse: 'collapse',
                                    tableLayout: 'fixed',
                                    textAlign: 'left'
                                  }}>
                                    <tbody>
                                    <tr>
                                      <td style={{width: '10%'}}>
                                        <input
                                            type="radio"
                                            name={report.key} // Group radio buttons by report key
                                            value={index}
                                            checked={this.state.selectedResults[report.key] === index}
                                            onChange={() => this.handleSelection(report.key, index)}
                                        />
                                      </td>
                                      <td style={{width: '30%'}}>{item.name}</td>
                                      <td style={{width: '30%'}}>{item.regno}</td>
                                      <td style={{width: '30%'}}>{item.EnquiryID}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </label>
                            </div>
                        ))}
                      </div>
                  );
                } else {
                  return (
                      <div key={reportIndex}>
                        <h3>{report.key.toUpperCase()} RESULT</h3>
                        <p>No Result</p>
                      </div>
                  );
                }
              })}
            </CenteredBox>

            <CenteredBox noPadding stacked>
              <Button
                  onClick={this.onReturnToPreviousPage}
                  classNames={["next", "centered", "large"]}
                  theme="green"
                  text="BACK"
                  url="/services/comprehensive-3-in-1-consumer-credit-report/submission"
              />
            </CenteredBox>

            <Spacer />

            <CenteredBox noPadding stacked>
              <Button
                  type="button"
                  classNames={["next", "centered", "large"]}
                  theme="green"
                  text="PAY"
                  onClick={() => {
                    const { inoxico, tu, xds } = this.state.selectedResults;


                    if (inoxico !== null && tu !== null && xds !== null) {
                      // Retrieve the selected reports
                      const selectedReports = {
                        inoxico: this.props.search_result.public_3in1_report_data.inoxico.results[inoxico],
                        tu: this.props.search_result.public_3in1_report_data.tu.results[tu],
                        xds: this.props.search_result.public_3in1_report_data.xds.results[xds],
                      };

                      // Now submit the selected reports
                      this.onSubmit(selectedReports);

                    } else {
                      alert('Please select one result from each report.');
                    }
                  }}
              />
            </CenteredBox>
          </React.Fragment>
      );

    }
  };

  onSubmit = (selectedReports) => {
    console.log('Selected Reports:');
    console.log(selectedReports);
    const data = {
      selectedReports, // Pass only the selected reports
      public_3in1_token: this.props.token,
      code: 200
    };

    console.log('Submitting data:');
    console.log(data);

    // Submit the selected reports
    this.props.submit3in1SearchResult(data, this);
  };

  onReturnToPreviousPage = () => {
    this.props.previousPage();
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <React.Fragment>
        <Section>
          <SectionHeader noPadding autoWidth title="Search Results" />
          <p style={{ marginBottom: '10px', paddingBottom: '10px', textAlign: 'center'}}>Please select one result from each report.</p>
          <Container>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              {this.renderSearchData()}
              <Spacer />
            </form>
          </Container>
        </Section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.threeInOneReport.public_3in1_token,
    search_result: state.threeInOneReport.search_result,
  };
};

const formWrapped = reduxForm({
  form: "wizard", // <------ same form name
  destroyOnUnmount: true, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(ReportRegisterSecondPage);

export default connect(mapStateToProps, {
  fetchResults,
  submit3in1SearchResult,
  throwError,
})(formWrapped);
