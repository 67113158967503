export const BASE_URL = "https://www.accountability.co.za";
//export const BASE_URL = "https://www.accountability.stageoptimal.co.za";
// export const BASE_URL = "http://dynamic.accountability.co.za:8888";
 //export const BASE_URL = "http://http://127.0.0.1:8000";
export const ADMIN_URL = "https://admin.accountability.co.za/api";
// export const ADMIN_URL = "https://admin.accountability.stageoptimal.co.za/api";
//  export const ADMIN_URL = "http://127.0.0.1:8000/api";

 // const LOCAL_PATH = "https://admin.accountability.local/storage/";
 // const STAGE_PATH = "https://admin.accountability.stageoptimal.co.za/storage/";
const PRODUCTION_PATH = "https://admin.accountability.co.za/storage/";


export const API_IMAGE_PATH = PRODUCTION_PATH;