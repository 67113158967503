import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import errorReducer from "./errorReducer";
import feedbackReducer from "./feedbackReducer";
import modalReducer from "./modalReducer";
import loadingReducer from "./loadingReducer";
import socialFeedReducer from "./socialFeedReducer";
import accountTypeReducer from "./accountTypeReducer";
import institutionReducer from "./institutionReducer";
import registerReducer from "./registerReducer";
import serviceReducer from "./serviceReducer";
import newsReducer from "./newsReducer";
import testimonialReducer from "./testimonialReducer";
import ageVerifiedReducer from "./ageVerifiedReducer";
import downloadReducer from "./downloadReducer";
import threeInOneReducer from "./threeInOneReducer";
import fourInOneReducer from "./fourInOneReducer";
import payFastReducer from "./payFastReducer";
import pagesReducer from './pagesReducer'

export default combineReducers({
  form: formReducer,
  stateErrors: errorReducer,
  feedback: feedbackReducer,
  modal: modalReducer,
  isLoading: loadingReducer,
  socialFeed: socialFeedReducer,
  accountTypes: accountTypeReducer,
  institutions: institutionReducer,
  register: registerReducer,
  services: serviceReducer,
  news: newsReducer,
  testimonials: testimonialReducer,
  isVerified: ageVerifiedReducer,
  documentation: downloadReducer,
  threeInOneReport: threeInOneReducer,
  fourInOneReport: fourInOneReducer,
  payFastForm: payFastReducer,
  pages: pagesReducer
});
